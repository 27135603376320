import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'

export default function InvoiceCcEmailInputSection(props) {
  const {
    isForQuote,
    hasInvoiceCcEmail, setHasInvoiceCcEmail,
    invoiceCcEmail, setInvoiceCcEmail,
  } = props

  return (
    <div className='invoiceCcEmailInputSection'>
      <FormControlLabel
        control={(
          <Checkbox
            color='primary'
            checked={hasInvoiceCcEmail}
            onClick={() => setHasInvoiceCcEmail(!hasInvoiceCcEmail)}
          />
        )}
        label={`I would like my financial department to receive a copy of the ${isForQuote ? 'quote' : 'invoice'}`}
      />
      {hasInvoiceCcEmail && (
        <div className='invoiceCcEmailInputSection-textInputContainer'>
          <TextField
            required
            id='invoiceCcEmail'
            name='invoiceCcEmail'
            label='email'
            className='invoiceCcEmailInputSection-textInputContainer-textField'
            value={invoiceCcEmail}
            onChange={(event) => setInvoiceCcEmail(event.target.value ? event.target.value.trim() : '')}
          />
        </div>
      )}
    </div>
  )
}
