const API = process.env.REACT_APP_PLICKERS_API_ENDPOINT

function headers() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-version': '4.0.0',
    'Cache-Control': 'max-age=0, private, must-revalidate',
    Pragma: 'no-cache',
  }
}

function parseResponse(response) {
  if (response.status !== 204) {
    const contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json().then((json) => {
        if (!response.ok) {
          return Promise.reject(json)
        }
        return json
      })
    }
    return response.text().then((text) => text)
  }
}

function checkStatus(response) {
  if (response.ok) {
    return response
  }
  if (response && response.status) {
    const error = new Error(response.status)
    error.response = response
    throw error
  } else {
    const error = new Error()
    throw error
  }
}

function queryString(params) {
  const query = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')
  return `${query.length ? '?' : ''}${query}`
}

function fetchRetry(url, options) {
  const delay = 100 // delay in ms before retry request
  const limit = 2 // max number request repeats
  return new Promise((resolve, reject) => {
    const wrappedFetch = function wrappedFetch(limit) {
      fetch(url, options)
        .then((response) => {
          if (response.status !== 503) { // only retry on 503 errors
            resolve(response)
          } else if (limit > 0) {
            retry(limit)
          } else {
            reject(response)
          }
        })
        .catch((error) => {
          if (limit > 0) {
            retry(limit)
          } else {
            reject(error)
          }
        })
    }
    function retry(n) {
      if (process.env.REACT_APP_ENV !== 'production') {
        console.log(`************Retry request: attempt ${limit - n + 1} *******************`)
      }
      setTimeout(() => {
        wrappedFetch(n - 1)
      }, delay)
    }
    wrappedFetch(limit)
  })
}

export default {
  fetch(url, params = {}) {
    return fetchRetry(`${API}${url}${queryString(params)}`, {
      method: 'GET',
      headers: headers(),
    })
      .then(checkStatus)
      .then(parseResponse)
  },
  post(url, data) {
    const body = JSON.stringify(data)
    return fetchRetry(`${API}${url}`, {
      method: 'POST',
      headers: headers(),
      body,
    })
      .then(checkStatus)
      .then(parseResponse)
  },
  put(url, data) {
    const body = JSON.stringify(data)
    return fetchRetry(`${API}${url}`, {
      method: 'PUT',
      headers: headers(),
      body,
    })
      .then(checkStatus)
      .then(parseResponse)
  },
}
