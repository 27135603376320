import React from 'react'
import AmountDetails from '../AmountDetails'
import PurchaserSummary from '../PurchaserSummary'
import TaxSummary from '../TaxSummary'

export default function Review(props) {
  const {
    itemName,
    seatCount,
    currency,
    purchaser,
    hasTaxId,
    taxIdObject,
    discountRates,
    hasPo,
    poNumber,
    poFile,
    hasInvoiceCcEmail,
    invoiceCcEmail,
    reviewDescription,
  } = props

  let invoiceCcEmailText = ''
  if (hasInvoiceCcEmail) {
    invoiceCcEmailText = itemName === 'quote' ?
      `The quote and purchase link will also be sent to ${invoiceCcEmail}` :
      `The invoice and payment link will also be sent to ${invoiceCcEmail}`
  }

  return (
    <div className='orderFormReviewContainer'>
      <div className='orderFormReviewContainer-description'>
        {reviewDescription}
      </div>
      <div className='orderFormReviewContainer-purchaser'>
        <h2>Purchaser</h2>
        <PurchaserSummary
          purchaser={purchaser}
        />
      </div>
      {hasInvoiceCcEmail && (
        <div className='orderFormReviewContainer-invoiceCcEmail'>
          {invoiceCcEmailText}
        </div>
      )}
      {hasTaxId && (
        <React.Fragment>
          <h2>Tax ID</h2>
          <TaxSummary taxIdObject={taxIdObject} />
        </React.Fragment>
      )}
      {hasPo && (
        <React.Fragment>
          <h2>Purchase order</h2>
          <div className='orderFormReviewContainer-purchaseOrder'>
            <div className='orderFormReviewContainer-purchaseOrder-poNumber'>{poNumber}</div>
            <div className='orderFormReviewContainer-purchaseOrder-poFileName'>
              {`(${poFile.name})`}
            </div>
          </div>
        </React.Fragment>
      )}
      <div className='orderFormReviewContainer-orderSummary'>
        <h2>Summary</h2>
        <AmountDetails
          seatCount={seatCount}
          currency={currency}
          discountRates={discountRates}
        />
      </div>
    </div>
  )
}
